<template>
  <v-app>
    <div v-if="authorized">
      <default-bar />
      <default-drawer />

      <default-footer />
      <!-- <default-settings /> -->
    </div>
    <default-view />
  </v-app>
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "DefaultLayout",
  computed: {
    ...get("auth", ["authorized", "authstatus"]),
  },
  components: {
    DefaultBar: () => import("./AppBar"),
    DefaultDrawer: () => import("./Drawer"),
    DefaultFooter: () => import("./Footer"),
    DefaultSettings: () => import("./Settings"),
    DefaultView: () => import("./View"),
  },
};
</script>
